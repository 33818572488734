import React, { useState, useEffect } from 'react';
import '../css/AddressScreen.css';
import { getAddress, getZtAddress, deleteAddress, saveAddress } from '../actions/addressActions';
import { saveShippingAddress } from '../actions/cartActions';

import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import LoadSpinner from '../components/LoadSpinner';
import Loader from '../components/Loader';
import { messageUpdate } from '../actions/messageActions';
import { Select, Form, Input } from 'antd';
import { useMediaQuery } from 'react-responsive';

const { Option } = Select;

//初始化表单字段：当用户开始添加一个新地址时，表单需要被初始化。defaultVal 提供了这些表单字段的初始值，即每个字段都被初始化为空字符串或适当的默认值（如 status 初始化为 0）。这样可以确保表单在开始时处于一个空白状态

const defaultVal = {
  address: '',
  city: '',
  county: '',
  country: '',
  gs: '',
  phone: '',
  postcode: '',
  status: 0,
  uname: '',
  email: '',
};

//组件的主函数，虚拟 DOM 树的重新渲染和更新主要依赖于 React 组件的 状态 (state) 和 属性 (props) 的变化
function SelAddressScreen(props) {
  console.log('Received props:', props);
  let { history } = props;
  console.log(props.isMobile);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const addInfo = useSelector((state) => state.addresses);
  const messageInfo = useSelector((state) => state.message);
  const pickUpAddr = useSelector((state) => state.pickUpAddr.ztAddress);
  const [show, setShow] = useState(false);
  // const [email, setEmail] = useState('');
  const [deliverType, setDeliverType] = useState('deliver');
  const cart = useSelector((state) => state.cart);

//useState('');是email的初始化的值，然后只要虚拟dom里面调用了setEmail并且赋值，那么email的值就会变化。
  const [email, setEmail] = useState('');

  const [editAddress, setEditAddress] = useState({});

  const dispatch = useDispatch();

//使用 useEffect 钩子在组件挂载时执行副作用操作：
//如果用户已登录 (userInfo 存在)，就从服务器获取用户的地址列表。
//无论是否登录，都获取自提地址列表。
  useEffect(() => {
    if (userInfo) dispatch(getAddress());
    dispatch(getZtAddress());
  }, [dispatch]);

//使用 useMediaQuery 钩子判断当前设备是否为移动设备。如果窗口宽度小于 1000px，则 isMobile 为 true。
  const isMobile = useMediaQuery({
    query: '(max-width: 1000px)',
  });


//使用 antd 提供的 Form.useForm 钩子创建表单实例：
//addForm 用于管理新增地址的表单。
//editForm 用于管理编辑地址的表单。
  const [addForm] = Form.useForm();
  const [editForm] = Form.useForm();


//deleteAddressHandle 是一个高阶函数，它返回一个用于处理删除地址的函数。调用时通过 dispatch 删除指定 id 的地址。
  const deleteAddressHandle = (id) => {
    return () => {
      dispatch(deleteAddress(id));
    };
  };


//closeAddress 函数用于关闭地址编辑模态框，并重置表单的字段值。它会调用 setFieldsValue 方法将表单重置为默认值 (defaultVal)，然后通过 setShow(false) 关闭模态框。
  const closeAddress = () => {
    console.log('清晰 closeAddress - >:');
    addForm.setFieldsValue({ ...defaultVal });
    editForm.setFieldsValue({});
    setShow(false);
  };


//saveAddressHanle 是用于保存新地址的函数：
//从 addForm 中获取用户输入的表单值 (getFieldsValue)，然后进行一系列验证。
//如果验证通过，将 email 添加到地址对象中。
//如果用户已登录，调用 saveAddress 保存地址，并在成功后关闭模态框。
//如果用户未登录，保存地址到 saveShippingAddress，并重定向到游客结账页面。
  const saveAddressHanle = () => {
    const addressObj = addForm.getFieldsValue(true);
    console.log('addressObj - >:', addressObj);

    if (!addressObj.country?.trim()) {
      dispatch(messageUpdate('Please type in country'));
      return;
    }
    if (!addressObj.uname?.trim()) {
      dispatch(messageUpdate('Please type in Full Name'));
      return;
    }
    if (!addressObj.gs?.trim()) {
      dispatch(messageUpdate('Please type in Company'));
      return;
    }
    if (!addressObj.phone?.trim()) {
      dispatch(messageUpdate('Please type in Phone Number'));
      return;
    }
    if (!addressObj.postcode?.trim()) {
      dispatch(messageUpdate('Please type in Postcode'));
      return;
    }
    if (!addressObj.address?.trim()) {
      dispatch(messageUpdate('Please type in Address'));
      return;
    }
    if (!addressObj.city?.trim()) {
      dispatch(messageUpdate('Please type in City'));
      return;
    }
    if (!addressObj.county?.trim()) {
      dispatch(messageUpdate('Please type in County'));
      return;
    }
    if (!userInfo?.email && !email?.trim()) {
      dispatch(messageUpdate('Please type in Email'));
      return;
    }

    addressObj.email = email;

    if (userInfo) {
      dispatch(saveAddress(addressObj, closeAddress));
    } else {
      dispatch(
        saveShippingAddress(addressObj, () => {
          history.push('/placeorder/tourists-shipping');
        }),
      );
    }

    closeAddress();
  };


  //useAddressHanle 用于保存用户编辑的地址信息，并将其设置为当前订单的配送地址。保存成功后，跳转到订单确认页面。
  const useAddressHanle = () => {
    const editObj = editForm.getFieldsValue(true);
    editObj.id = editAddress.id;

    dispatch(
      saveAddress(editObj, () => {
        dispatch(
          saveShippingAddress(editObj, () => {
            history.push('/placeorder/' + editObj.id);
          }),
        );
      }),
    );
  };



//navTo 是一个高阶函数，返回一个函数用于保存选择的地址，并导航到订单确认页面。
  const navTo = (item) => {
    return () => {
      dispatch(
        saveShippingAddress(item, () => {
          history.push('/placeorder/' + item.id);
        }),
      );
    };
  };


  //navTo2 用于处理自提地址的选择：
//如果用户未登录并且未提供电子邮件，则提示输入电子邮件。
//将自提地址信息和电子邮件保存到 saveShippingAddress，然后导航到自提订单确认页面。
  const navTo2 = () => {
    if (!userInfo?.email && !email?.trim()) {
      dispatch(messageUpdate('Please type in Email'));
      return;
    }
    dispatch(
      saveShippingAddress(
        {
          ...pickUpAddr,
          email: email,
        },
        () => {
          history.push('/placeorder/zitiquhuo');
        },
      ),
    );
  };

  return (
    <div className="AddressScreen selAddress right">
      {show ? (
        <div className="mask">
          <div className="modal">
            <div className="SelTitle">
              Edit address{' '}
              <img
                src="./images/index/errs.png"
                className="err"
                onClick={() => {
                  setShow(false);
                }}
              />
            </div>

            <Form
              name="basic-edit"
              form={editForm}
              labelCol={{
                span: 5,
              }}
              wrapperCol={{
                span: 19,
              }}
              style={{
                maxWidth: isMobile ? '100%' : '48%',
              }}
            >
              <Form.Item
                name="country"
                label="Country"
              >
                <Select placeholder="Select Country">
                  <Option value="volvo">Select Country</Option>
                  <Option value="Belgium">Belgium</Option>
                  <Option value="Czech Republic">Czech Republic</Option>
                  <Option value="Denmark">Denmark</Option>
                  <Option value="Finland">Finland</Option>
                  <Option value="France">France</Option>
                  <Option value="Germany">Germany</Option>
                  <Option value="Greece">Greece</Option>
                  <Option value="Hungary">Hungary</Option>
                  <Option value="Iceland">Iceland</Option>
                  <Option value="Ireland">Ireland</Option>
                  <Option value="Italy">Italy</Option>
                  <Option value="Norway">Norway</Option>
                  <Option value="Poland">Poland</Option>
                  <Option value="Portugal">Portugal</Option>
                  <Option value="Spain">Spain</Option>
                  <Option value="Sweden">Sweden</Option>
                  <Option value="Switzerland">Switzerland</Option>
                  <Option value="United Kingdom">United Kingdom</Option>
                </Select>

              </Form.Item>
              <Form.Item
                label="Full Name"
                name="uname"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Company"
                name="gs"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Postcode"
                name="postcode"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Address"
                name="address"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="County"
                name="county"
              >
                <Input />
              </Form.Item>
            </Form>

            <div
              className="TextYellowBtn btn"
              style={{ display: 'inline-block', marginBottom: '0.3rem' }}
              onClick={useAddressHanle}
            >
              Use this address
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {addInfo && addInfo.loading2 && <Loader />}
      {messageInfo.msg ? <Message variant={messageInfo.variant}>{messageInfo.msg}</Message> : ''}

      {/* <div className="err" onClick={closeAddress}>
						<img src="./images/index/errs.png" />
					</div> */}
      <div className={'inAddress ' + (props.isMobile ? 'mobile' : '')}>
        <div>
          <label
            onClick={() => {
              setDeliverType('deliver');
            }}
            className={deliverType === 'deliver' ? 'formCheckLabel checked' : 'formCheckLabel'}
          ></label>
        </div>
        <div className="flex-between lisOne">
          <div className="SelTitle">Select a delivery address</div>
          {deliverType === 'deliver' ? (
            <div>
              <p>
                Is the address you'd like to use displayed below? if so, click the corresponding "
                Deliver to this address" button Or you can{' '}
                <span className="textGreen">enter a new delivery address</span>
              </p>
              {addInfo && addInfo.loading ? (
                <div className="fullcreen">
                  <LoadSpinner />
                </div>
              ) : (
                <div className="w100">
                  <div
                    className="flex boxUi"
                    style={{ marginBottom: 0, alignItems: 'stretch', justifyContent: 'flex-start' }}
                  >
                    {userInfo &&
                      addInfo.addresses &&
                      addInfo.addresses.map((item, index) => {
                        return (
                          <div
                            key="index"
                            className={
                              'boxUi_item ' + (item.status == 1 ? 'boxUi_item_default' : '')
                            }
                          >
                            <div className="boxUi_item_default_body">
                              <div className="flex-center">
                                <div className="boxName">{item.uname}</div>
                                {/* <div className="mar">●</div>
													<div>{item.phone}</div> */}
                              </div>
                              {/* <div className="boxName names">{item.gs}</div> */}

                              <div className="select_item">
                                <div>{item.country}</div>
                                <div>{item.county}</div>
                                <div>{item.city}</div>
                                <div>{item.address}</div>
                                {/* <div>County</div>
													<div>Country</div> */}
                              </div>
                              <div style={{ position: 'absolute', bottom: '.1rem', left: 0 }}>
                                <div
                                  className="TextYellowBtn btn"
                                  onClick={navTo(item)}
                                >
                                  delivery to this address
                                </div>
                                <div
                                  className="flex-between"
                                  style={{ marginTop: '.1rem', padding: '0.1rem' }}
                                >
                                  <div
                                    className="TextGreenBtn btn"
                                    onClick={() => {
                                      editForm.setFieldsValue({ ...item });
                                      setEditAddress(item);
                                      setShow(true);
                                    }}
                                  >
                                    Edit
                                  </div>
                                  <div
                                    className="TextGreenBtn btn"
                                    onClick={deleteAddressHandle(item.id)}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {!userInfo || !userInfo.email ? (
                <div
                  className="lis_item flex-center"
                  style={{ margin: '0.5rem 0' }}
                >
                    <Form.Item
                      label="Email"
                      labelCol={{ span: 5 }}
                      wrapperCol={{ span: 19 }}
                      style={{maxWidth: isMobile ? '100%' : '48%',}}
                    >
                      <Input
                        placeholder=""
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        className="entryOne"
                      />
                    </Form.Item>


                  {/* e.target.value是输入框的值，setEmail来把输入框的值定义email */}

                </div>
              ) : (
                ''
              )}

              <div className="SelTitle">Add a new address</div>
              <Form
                name="basic-new"
                form={addForm}
                labelCol={{
                  span: 5,
                }}
                wrapperCol={{
                  span: 19,
                }}
                style={{
                  maxWidth: isMobile ? '100%' : '48%',
                }}
              >
                <Form.Item
                  name="country"
                  label="Country"
                >
                    <Select placeholder="Select Country">
                      <Option value="volvo">Select Country</Option>
                      <Option value="Belgium">Belgium</Option>
                      <Option value="Czech Republic">Czech Republic</Option>
                      <Option value="Denmark">Denmark</Option>
                      <Option value="Finland">Finland</Option>
                      <Option value="France">France</Option>
                      <Option value="Germany">Germany</Option>
                      <Option value="Greece">Greece</Option>
                      <Option value="Hungary">Hungary</Option>
                      <Option value="Iceland">Iceland</Option>
                      <Option value="Ireland">Ireland</Option>
                      <Option value="Italy">Italy</Option>
                      <Option value="Norway">Norway</Option>
                      <Option value="Poland">Poland</Option>
                      <Option value="Portugal">Portugal</Option>
                      <Option value="Spain">Spain</Option>
                      <Option value="Sweden">Sweden</Option>
                      <Option value="Switzerland">Switzerland</Option>
                      <Option value="United Kingdom">United Kingdom</Option>
                    </Select>


                </Form.Item>
                <Form.Item
                  label="Full Name"
                  name="uname"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Company"
                  name="gs"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Postcode"
                  name="postcode"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Address"
                  name="address"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="County"
                  name="county"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  wrapperCol={{ offset: isMobile ? 0 : 5, span: 19 }}
                  style={{ marginBottom: 0 }}
                >
                  {props.isMobile ? (
                    <button
                      className="tb-add-address-btn"
                      onClick={saveAddressHanle}
                    >
                      Add address
                    </button>
                  ) : (
                    <div
                      className="TextYellowBtn btn"
                      style={{ display: 'inline-block' }}
                      onClick={saveAddressHanle}
                    >
                      Add address
                    </div>
                  )}
                </Form.Item>
              </Form>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      {props.isMobile ? (
        <div className="lisOne">
            <button
              className="tb-picking-up-btn "
              onClick={() => navTo2({})}  // 这里传递一个匿名函数来包裹 navTo2
            >
              Picking up
            </button>
        </div>
      ) : (
        <div className="inAddress">
          <div>
            <label
              onClick={() => {
                setDeliverType('pickingUp');
              }}
              className={deliverType === 'pickingUp' ? 'formCheckLabel checked' : 'formCheckLabel'}
            ></label>
          </div>
          <div className="flex-between lisOne">
            <div className="SelTitle">Picking Up</div>
            {deliverType === 'pickingUp' ? (
              pickUpAddr && pickUpAddr.id ? (
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      width: '100%',
                      maxWidth: '500px',
                      background: '#DEF4F2',
                      margin: '.1rem 0',
                      padding: '0.1rem',
                    }}
                  >
                    <div className="boxName">{pickUpAddr.dizhi}</div>
                    <div style={{ margin: '0.1rem 0' }}>{pickUpAddr.dz1}</div>
                    <div style={{ margin: '0.1rem 0' }}>{pickUpAddr.dz2}</div>
                    <div style={{ margin: '0.1rem 0' }}>{pickUpAddr.dz3}</div>
                    <div style={{ margin: '0.1rem 0' }}>{pickUpAddr.postcode}</div>
                    <div style={{ margin: '0.1rem 0' }}>{pickUpAddr.tel}</div>
                  </div>
                  {!userInfo || !userInfo.email ? (
                    <div
                      className="lis_item flex-center"
                      style={{ margin: '0.5rem 0' }}
                    >
                      <div className="subjectOne">Email:</div>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        className="entryOne"
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  <div
                    className="TextYellowBtn btn"
                    style={{ display: 'inline-block', marginBottom: '.1rem' }}
                    onClick={() => navTo2({})}
                  >
                    Next
                  </div>
                </div>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SelAddressScreen;
